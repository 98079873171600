import React from 'react'
import { MDXTag } from '@mdx-js/tag'


const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><div>
Hi I'm Sergey, welcome to my technical blog about technologies, tools, patterns, ideas and resources for web.<br />
I work as a web developer for <span>{new Date().getFullYear() - 2006}</span> years and I live in Toronto, Canada. I love to work on complex and scalable web applications as well as on small, single page, interactive sites. You can connect with me on <a href="https://www.linkedin.com/in/sergeyzarovskiy/">Linkedin</a> or check my social media links below
      </div>
           </MDXTag>;
  }

}
export const _frontmatter = {
  "title": "About",
  "date": "2019-01-17T16:57:36.000Z",
  "copyright": "ESO/José Francisco Salgado",
  "overview": "Learn about web technologies and more at Sergeyski",
  "isInTopBar": true
};
    